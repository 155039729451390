import { GetLoggedUserUseCase } from "@authentication/domain/usecases/get-logged-user.usecase";
import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { GetAllBeneficiariesUseCase } from "@beneficiary/domain/usecases/get-all-beneficiaries.usecase";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    GlobalEntity,
    GlobalEntitySearchFilters,
} from "@entity/domain/models/global-entity.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { ActivitySummary } from "@project/domain/models/activity-summary.model";
import {
    Catalogue,
    CatalogueEnum,
} from "@project/domain/models/catalogue.model";
import { GlobalProject } from "@project/domain/models/global-project.model";
import { GetAllCataloguesUseCase } from "@project/domain/usecases/get-all-catalogues.usecase";
import { GetAllGlobalProjectsByUseCase } from "@project/domain/usecases/get-all-global-projects-by.usecase";
import { GetAllProjectActivitiesSummaryByUseCase } from "@project/domain/usecases/project-activity/get-all-summary-activities-by.usecase";
import { RcFile } from "antd/es/upload";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class DerivationModalFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    beneficiaries: BeneficiarySummary[] = [];

    @observable
    catalogues: Catalogue[] = [];

    @observable
    selectedCatalogue: CatalogueEnum | null = null;

    @observable
    globalEntities: GlobalEntity[] = [];

    @observable
    globalProjects: GlobalProject[] = [];

    @observable
    activities: ActivitySummary[] = [];

    entitiesFiltersValue: GlobalEntitySearchFilters = {
        catalogue: null,
    };

    @observable
    entitiesFilters: GlobalEntitySearchFilters = this.entitiesFiltersValue;

    constructor(
        @inject(GetAllBeneficiariesUseCase)
        private readonly getAllBeneficiariesUseCase: GetAllBeneficiariesUseCase,
        @inject(GetAllCataloguesUseCase)
        private readonly getAllCataloguesUseCase: GetAllCataloguesUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
        @inject(GetAllGlobalProjectsByUseCase)
        private readonly getAllGlobalProjectsByUseCase: GetAllGlobalProjectsByUseCase,
        @inject(GetAllProjectActivitiesSummaryByUseCase)
        private readonly getAllProjectsActivitiesSummaryByUseCase: GetAllProjectActivitiesSummaryByUseCase,
        @inject(GetLoggedUserUseCase)
        private readonly getLoggedUserUseCase: GetLoggedUserUseCase,
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllBeneficiaries(),
            this.getAllCatalogues(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllBeneficiaries(): Promise<void> {
        const beneficiaries = await this.getAllBeneficiariesUseCase.execute();

        runInAction(() => {
            this.beneficiaries = beneficiaries;
        });
    }

    async getAllCatalogues(): Promise<void> {
        const catalogues = await this.getAllCataloguesUseCase.execute();

        runInAction(() => {
            this.catalogues = catalogues;
        });
    }

    async getAllGlobalEntities(
        filters?: GlobalEntitySearchFilters,
    ): Promise<void> {
        const user = this.getLoggedUserUseCase.execute();
        const globalEntities =
            await this.getAllGlobalEntitiesUseCase.execute(filters);

        const filteredEntities = user.entityId
            ? globalEntities.filter((entity) => entity.id !== user.entityId)
            : globalEntities;

        runInAction(() => {
            this.globalEntities = filteredEntities;
        });
    }

    async getAllGlobalProjectByEntityId(
        entityId: number,
        catalogueId: CatalogueEnum,
    ): Promise<void> {
        const globalProjects = await this.getAllGlobalProjectsByUseCase.execute(
            {
                entityId,
                catalogueId,
                isActive: true,
            },
        );
        runInAction(() => {
            this.globalProjects = globalProjects;
        });
    }

    async getAllProjectActivities(projectId: number): Promise<void> {
        const activities =
            await this.getAllProjectsActivitiesSummaryByUseCase.execute(
                projectId,
            );

        runInAction(() => {
            this.activities = activities;
        });
    }

    @action
    clearProjects(): void {
        this.globalProjects = [];
    }

    @action
    clearActivities(): void {
        this.activities = [];
    }

    async addDocument(
        name: string,
        originFileObj: RcFile,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document = await this.createDocumentUseCase.execute(
            new CreateDocument(name, originFileObj),
        );

        if (document) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
        return document;
    }
}
