import { Undefinable } from "@core/domain/types/undefinable.type";
import { ExpenseRecurrenceEnum } from "./expense-recurrence.model";
import { ExpenseTypeEnum } from "./expense-type.model";

export class CreateExpense {
    constructor(
        public beneficiaryId: Expense["beneficiaryId"],
        public concept: Expense["concept"],
        public amount: Expense["amount"],
        public type: Expense["type"],
        public recurrence: Expense["recurrence"],
    ) {}
}

export class EditExpense extends CreateExpense {
    constructor(
        public id: Expense["id"],
        beneficiaryId: Expense["beneficiaryId"],
        concept: Expense["concept"],
        amount: Expense["amount"],
        type: Expense["type"],
        recurrence: Expense["recurrence"],
    ) {
        super(beneficiaryId, concept, amount, type, recurrence);
    }
}

export class Expense {
    public isActive?: Undefinable<boolean>;

    constructor(
        public id: number,
        public beneficiaryId: number,
        public concept: string,
        public amount: number,
        public type: ExpenseTypeEnum,
        public recurrence: ExpenseRecurrenceEnum,
    ) {}
}

export class Expenses {
    constructor(
        public readonly expenses: Expense[],
        public readonly count: number,
    ) {}
}

export interface ExpenseSearchFilters {
    beneficiaryId?: Undefinable<number>;
}
