import { ExpenseDto } from "@beneficiary/data/dto/beneficiary.dto";
import { CreateExpenseBody } from "@beneficiary/data/dto/create-beneficiary.body";
import { EditExpenseBody } from "@beneficiary/data/dto/edit-beneficiary.body";
import { ExpenseRecurrenceEnumMapper } from "@beneficiary/data/mappers/expense-recurrence-enum.mapper";
import { ExpenseTypeEnumMapper } from "@beneficiary/data/mappers/expense-type-enum.mapper";
import {
    CreateExpense,
    EditExpense,
    Expense,
} from "@beneficiary/domain/models/economic-data/expense/expense.model";
import { ExpenseFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/economyData/expenses/expense-form.view";
import { Nullable, isNullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class ExpenseMapper {
    constructor(
        @inject(ExpenseTypeEnumMapper)
        private readonly expenseTypeEnumMapper: ExpenseTypeEnumMapper,
        @inject(ExpenseRecurrenceEnumMapper)
        private readonly expenseRecurrenceEnumMapper: ExpenseRecurrenceEnumMapper,
    ) {}

    map(expenseDto: ExpenseDto): Nullable<Expense> {
        const errors = validateSync(expenseDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const type = this.expenseTypeEnumMapper.map(expenseDto.type);
        if (isNullable(type)) return null;

        const recurrence = this.expenseRecurrenceEnumMapper.map(
            expenseDto.recurrence,
        );
        if (isNullable(recurrence)) return null;

        return new Expense(
            expenseDto.id,
            expenseDto.beneficiary,
            expenseDto.concept,
            parseFloat(expenseDto.amount),
            type,
            recurrence,
        );
    }

    mapToCreate(expenseForm: ExpenseFormValuesValidated): CreateExpense {
        if (!expenseForm.beneficiaryId)
            throw new Error("Missing beneficiaryId");

        return new CreateExpense(
            expenseForm.beneficiaryId,
            expenseForm.concept,
            expenseForm.amount,
            expenseForm.type,
            expenseForm.recurrence,
        );
    }

    mapToCreateDto(expense: CreateExpense): CreateExpenseBody {
        const type = this.expenseTypeEnumMapper.mapToDto(expense.type);
        const recurrence = this.expenseRecurrenceEnumMapper.mapToDto(
            expense.recurrence,
        );

        return {
            beneficiary: expense.beneficiaryId,
            concept: expense.concept,
            amount: expense.amount.toString(),
            type,
            recurrence,
        };
    }

    mapToEdit(expenseForm: ExpenseFormValuesValidated): EditExpense {
        if (!expenseForm.id) throw new Error("Missing expense id");
        if (!expenseForm.beneficiaryId)
            throw new Error("Missing benefiicaryId");

        return new EditExpense(
            expenseForm.id,
            expenseForm.beneficiaryId,
            expenseForm.concept,
            expenseForm.amount,
            expenseForm.type,
            expenseForm.recurrence,
        );
    }

    mapToEditDto(expense: Expense): EditExpenseBody {
        const type = this.expenseTypeEnumMapper.mapToDto(expense.type);
        const recurrence = this.expenseRecurrenceEnumMapper.mapToDto(
            expense.recurrence,
        );

        return {
            id: expense.id,
            concept: expense.concept,
            amount: expense.amount.toString(),
            type,
            recurrence,
        };
    }
}
